import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import React from "react";
import { colorStatusProposal, globalFormatCurrencyBRL, globalTranslate, globalTranslatePayment, globalTranslateStatus } from "../../../../../shared/utils";
import ModalUpdateStatusProposal from "../Modals/ModalUpdateStatusProposal";
import { useAuthContext } from "../../../../../context";
import { Transaction } from "../../../../../shared/types/TTransaction";
import { InstallmentSetup } from "../../../../../shared/types/TIntallmentSetup";

interface ProposalDetailProps {
	rows: any;
	smDown: boolean;
	isSuperAdmin: boolean;
	loading: boolean;
	isUpdateStatus: () => void;
	onclickbuttonResend: (id: string, email: string) => void;
}

// Função para formatar datas
const formatDate = (date: string | undefined, defaultValue = "") => (date ? format(new Date(date), "dd/MM/yyyy") : defaultValue);

// Função para formatar o endereço
const addressFormatter = (row: any) => {
	if (row?.addressStreet) {
		const complement = row?.addressComplement ? ` - ${row?.addressComplement}` : "";
		return `${row.addressStreet}, ${row.addressNumber}${complement} - ${row.addressDistrict} - ${row.addressCity} - ${row.addressState} - ${row.addressZipcode}`;
	}
	return "";
};

const getContainerBackground = (index: number) => (index % 2 === 0 ? "white" : "#f0f1f1");

export const ProposalDetailCard: React.FC<ProposalDetailProps> = ({
	rows,
	smDown,
	isSuperAdmin,
	loading,
	isUpdateStatus,
	onclickbuttonResend
}) => {
	const [openModalUpdateStatus, setOpenModalUpdateStatus] = React.useState(false);
	const { loggedUser } = useAuthContext();

	const handleOpenModalUpdateStatus = () => {
		setOpenModalUpdateStatus(true);
	};

	const handleCloseModalUpdateStatus = () => {
		setOpenModalUpdateStatus(false);
	};

	const gridContainers = [
		{
			content: loading ? (
				<Skeleton width={100} />
			) : (
				<>
					<Grid item xs={12} md={5}>
						<Typography variant="caption" color="text.secondary">
							Origem da Garantia
						</Typography>
						<Typography variant="subtitle2" display={"flex"}>
							{rows?.createdFrom === "web_service" ? (
								<img src="/4seg.png" alt="4seg" style={{ width: "40px", height: "auto", marginRight: "8px" }} />
							) : (
								<img src="/logogar.png" alt="4seg" style={{ width: "30px", height: "auto", marginRight: "8px" }} />
							)}
							{globalTranslate(rows?.createdFrom)}
						</Typography>
					</Grid>
					<Grid item md={3} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Certificado
						</Typography>
						<Typography variant="subtitle2">{rows?.certificateNumber}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Criada
						</Typography>
						<Typography variant="subtitle2">{formatDate(rows?.createdAt)}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="80%" />
			) : (
				<Grid item xs={12}>
					<Typography variant="caption" color="text.secondary">
						Endereço
					</Typography>
					<Typography variant="subtitle2">{addressFormatter(rows)}</Typography>
				</Grid>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor Aluguel
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.rentAmount)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor Condominio
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.condominiumAmount)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor Energia Elétrica
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.electricEnergyAmount)}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor Gas
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.gasAmount)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor Agua
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.waterAmount)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Valor IPTU
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(rows?.iptuAmount)}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Início contrato locação:
						</Typography>
						<Typography variant="subtitle2">{formatDate(rows?.contractBegin)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Fim contrato locação:
						</Typography>
						<Typography variant="subtitle2">{formatDate(rows?.contractEnd)}</Typography>
					</Grid>
					<Grid item md={3.5} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Tombamento:
						</Typography>
						<Typography variant="subtitle2">{rows?.isTipping === "1" ? "Sim" : "Não"}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<Grid item xs={12}>
					<Typography variant="caption" color="text.secondary">
						Parceiro
					</Typography>
					<Typography variant="subtitle2">{rows?.client?.name}</Typography>
				</Grid>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Plano
						</Typography>
						<Typography variant="subtitle2">
							{rows?.plan?.description} {rows?.baseCalc === "encargos" ? " com encargos" : ""}
						</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Vigência
						</Typography>
						<Typography variant="subtitle2">
							{rows?.termBegin ? `${format(new Date(rows?.termBegin.substring(0, 10) + " 03:00:00"), "dd/MM/yyyy")}` : "2000-01-01"} {" a "}
							{rows?.expiresAt ? `${format(new Date(rows.expiresAt.substring(0, 10) + " 03:00:00"), "dd/MM/yyyy")}` : "2000-01-01"}
						</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Meses de Vigência
						</Typography>
						<Typography variant="subtitle2">{rows?.rentMonthsCount}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4}>
						<Typography variant="caption" color="text.secondary">
							Valor indenização
						</Typography>
						<Typography variant="subtitle2">{globalFormatCurrencyBRL(`${rows?.lmiAmount}`)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Mensalidade
						</Typography>
						<Typography variant="subtitle1" sx={{ fontWeight: "700" }} color={"primary"}>
							{globalFormatCurrencyBRL(rows?.assuranceMonthAmount)}
						</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Grid container direction="row" spacing={1}>
							<Grid item md={6}>
								<Typography variant="caption" color="text.secondary">
									Taxa Aplicada
								</Typography>
								<Typography variant="subtitle2">
									{rows?.propertyFinally === "commercial" ? `  ${rows?.commercialFee} %` : `${rows?.residentialFee} %`}
								</Typography>
							</Grid>
							<Grid item md={6}>
								<Typography variant="caption" color="text.secondary">
									LMI
								</Typography>
								<Typography variant="subtitle2">{rows?.lmi}X</Typography>
							</Grid>
						</Grid>
					</Grid>
				</>
			),
		},
		{
			content: loading ? (
				<Skeleton width="100%" />
			) : (
				<>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Tipo de cobrança
						</Typography>
						<Typography variant="subtitle2">{globalTranslatePayment(`${rows?.paymentMethod}`)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Tipo de Imovel
						</Typography>
						<Typography variant="subtitle2">{globalTranslate(`${rows?.propertyType}`)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Tipo de Locação
						</Typography>
						<Typography variant="subtitle2">{globalTranslate(`${rows?.propertyFinally}`)}</Typography>
					</Grid>
					<Grid item md={4} xs={12}>				</Grid>
					<Grid item md={4} xs={12}>
						<Typography variant="caption" color="text.secondary">
							Taxa de setup
						</Typography>
						<Typography variant="subtitle2">
							{rows &&
								rows.transaction?.rawAmount ? (parseFloat(rows.transaction?.rawAmount as string)).toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								}) : "Não cobrada"
							}
						</Typography>
					</Grid>
					<Grid item md={4} xs={12}>
						{rows &&
							rows.transaction?.status === "pending" ? (
							<Typography variant="subtitle2">
								<Button onClick={() => onclickbuttonResend(rows.installmentSetup?.id as string, rows.email as string)}>Reenviar link de pagamento</Button>
							</Typography>
						) : <>	</>}
					</Grid>
				</>
			),
		},
	];

	return (
		<>
			<Grid item xs={12} md={12}>
				<Grid container xs={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
					{/* Cabeçalho */}
					<Grid item xs={isSuperAdmin ? 7 : 8}>
						<Typography variant="h6" color={"primary"}>
							Dados da Garantia
						</Typography>
					</Grid>
					<Grid item xs={12} md={5} alignContent={"end"}>
						<Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
							<Typography variant="h5" color={loading ? "text.disabled" : colorStatusProposal(`${rows?.status}`)}>
								{loading ? <Skeleton width={100} /> : globalTranslateStatus(`${rows?.status && rows.status=='active' && ( rows?.termBegin && differenceInDays(new Date(rows?.termBegin),new Date())>0) ?'em Renovação':rows?.status}`)}
							</Typography>
							{isSuperAdmin && <CachedIcon sx={{ cursor: "pointer", fontSize: "30px" }} onClick={handleOpenModalUpdateStatus} />}
							
						</Box>
					</Grid>
					<Grid container flexDirection={smDown ? "column" : "row"}>
						{gridContainers.map((container, index) => (
							<Grid container item direction="row" key={index} sx={{ backgroundColor: getContainerBackground(index), padding: 0.5 }}>
								{container.content}
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>

			{loggedUser?.role == "admin" && isSuperAdmin && (
				<ModalUpdateStatusProposal rows={rows} isClose={handleCloseModalUpdateStatus} isOpenModal={openModalUpdateStatus} isUpdate={isUpdateStatus} />
			)}
		</>
	);
};
