import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	Icon,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";

interface IToolbarGridProposalProps {
	onClickAll?: () => void;
	onClickActive?: () => void;
	onClickEmitidas?: () => void;
	onClickExpires?: () => void;
	onClickExpired?: () => void;
	onClickCanceled?: () => void;
	onClickSuspended?: () => void;
	onClickSuspendedPending?: () => void;
	onClickSuspendedNotPending?: () => void;
	onClickRenovated?: () => void;
	onClickRenovation?: () => void;

	selectAll?: boolean;
	selectActive?: boolean;
	selectEmitidas?: boolean;
	selectRenovated?: boolean;
	selectExpires?: boolean;
	selectExpired?: boolean;
	selectSuspended?: boolean;
	selectCanceled?: boolean;
	selectRenovation?: boolean;
	selectSupendedNotPending?: boolean;
	selectSupendedPending?: boolean;

	showFilterDescription?: boolean;
	filterdescription?: string;
}

export const ToolbarGridProposal: React.FC<IToolbarGridProposalProps> = ({
	onClickAll,
	onClickActive,
	onClickEmitidas,
	onClickExpires,
	onClickExpired,
	onClickCanceled,
	onClickSuspended,
	onClickRenovated,
	onClickRenovation,
	onClickSuspendedNotPending,
	onClickSuspendedPending,

	selectAll = false,
	selectActive = false,
	selectEmitidas = false,
	selectRenovated = false,
	selectExpires = false,
	selectExpired = false,
	selectSuspended = false,
	selectCanceled = false,
	selectRenovation = false,
	selectSupendedPending = false,
	selectSupendedNotPending = false,

	showFilterDescription = false,
	filterdescription = "",
}) => {
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));

	// Estado para acompanhar a seleção nos submenus
	const [submenuSelection, setSubmenuSelection] = useState<Record<string, string>>({
		active: "active",
		suspended: "suspendedpending",
	});

	// JSON de configuração dentro do componente
	const buttonsConfig = [
		{
			id: "all",
			text: "Todos",
			icon: "done_all_icon",
			show: true,
			selected: selectAll,
			action: onClickAll,
		},
		{
			id: "active",
			text: "Vigentes",
			icon: "portrait_outlined_icon",
			show: true,
			selected: selectActive || selectEmitidas || selectExpires || selectRenovation || selectRenovated,
			action: () => {
				onClickActive?.();
				// Restaurar o submenu para "Todas Vigentes"
				setSubmenuSelection((prev) => ({ ...prev, active: "active" }));
			},
			submenu: [
				{ value: "active", label: "Todas Vigentes", action: onClickActive },
				{ value: "emited", label: "Garantias Contratadas", action: onClickEmitidas },
				{ value: "renovated", label: "Garantias Renovadas", action: onClickRenovated },
				{ value: "expires", label: "Garantias a Expirar", action: onClickExpires },
				{ value: "renovation", label: "Garantias em Renovação", action: onClickRenovation },
			],
		},
		{
			id: "expired",
			text: "Expiradas",
			icon: "pending_actionsOutlinedIcon",
			show: true,
			selected: selectExpired,
			action: onClickExpired,
		},
		{
			id: "suspended",
			text: "Suspensas",
			icon: "pause_circle_outlineOutlinedIcon",
			show: true,
			selected: selectSuspended || selectSupendedNotPending || selectSupendedPending,
			action: () => {
				onClickSuspended?.();
				setSubmenuSelection((prev) => ({ ...prev, suspended: "suspended" }));
			},
			submenu: [
				{ value: "suspended", label: "Todas suspensas", action: onClickSuspended },
				{ value: "suspendedpending", label: "Suspensas pendentes", action: onClickSuspendedPending },
				{ value: "suspendednotpending", label: "Suspensas não pendente", action: onClickSuspendedNotPending },
			],
		},
		{
			id: "canceled",
			text: "Canceladas",
			icon: "cancel_outlinedIcon",
			show: true,
			selected: selectCanceled,
			action: onClickCanceled,
		},
	];

	const handleSubmenuChange = (buttonId: string, event: SelectChangeEvent<string>, submenu?: { value: string; label: string; action?: () => void }[]) => {
		const selected = submenu?.find((item) => item.value === event.target.value);
		if (selected && selected.action) selected.action();
		setSubmenuSelection((prev) => ({ ...prev, [buttonId]: event.target.value }));
		console.log("buttonId", buttonId);
		console.log("event", event);
	};

	return (
		<>
			<Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
				{/* Grupo de botões */}
				<ButtonGroup aria-label="outlined primary button group">
					{buttonsConfig.map((button) =>
						button.show ? (
							<Button
								key={button.id}
								color="primary"
								disableElevation
								variant={button.selected ? "contained" : "outlined"}
								onClick={button.action}
								startIcon={<Icon>{button.icon}</Icon>}
							>
								{button.text}
							</Button>
						) : null
					)}
				</ButtonGroup>

				{/* Submenus apenas quando o botão correspondente estiver selecionado */}
				{buttonsConfig
					.filter((button) => button.submenu && button.selected)
					.map((button) => (
						<FormControl key={`${button.id}-submenu`} sx={{ minWidth: "150px" }} fullWidth={mdDown}>
							<InputLabel id={`${button.id}-label`}>{`Filtro de ${button.text}`}</InputLabel>
							<Select
								labelId={`${button.id}-label`}
								id={`${button.id}-select`}
								value={submenuSelection[button.id] || ""}
								onChange={(event) => handleSubmenuChange(button.id, event, button.submenu)}
								size="small"
								label={`Filtro de ${button.text}`}
							>
								{button.submenu?.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					))}
			</Box>

			<Typography variant="subtitle2" color="primary" sx={{ display: showFilterDescription ? "block" : "none", py: 1 }}>
				{filterdescription}
			</Typography>
		</>
	);
};